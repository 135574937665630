<script>
export default {
  name: "TelegramAdv",
};
</script>

<template>
  <div class="tg-adv">
    <div class="tg-adv__items">

      <p class="tg-adv__text">
        Горящие предложения от линий и ключевые события в логистике — в нашем
        <a
          class="tg-adv__text-link"
          href="https://t.me/vedexx_news"
          target="_blank"
          >телеграмм-канале</a
        >
      </p>

      <img class="tg-adv__img" src="@/assets/img/tg-qr.webp" alt="QR code" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.tg-adv {
  position: relative;
  z-index: 10;

  background-color: #e7f1fc;
  padding: 16px 24px;
  border-radius: 12px;

  margin-top: 48px;

  @media (max-width: $mobile-width) {
    margin-top: 40px;
  }

  &__items {
    display: flex;
    gap: 32px;
    align-items: center;

    max-width: 880px;
    margin: 0 auto;

     @media (max-width: $tablet-width) {
        padding: 0 32px;
     }

    @media (max-width: $mobile-width) {
      flex-direction: column;
      justify-content: center;
      gap: 24px;

      padding: unset;
    }
  }

  &__text {
    color: var(--grey-70);
    @include font-headline-2;

    &-link {
      color: inherit;

      @include media-hover("&:active") {
        text-decoration: none;
      }
    }

    @media (max-width: $tablet-width) {
      @include font-headline-3;
    }

    @media (max-width: $mobile-width) {
      text-align: center;
    }
  }

  &__img {
    max-width: 84px;
  }
}
</style>
