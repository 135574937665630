<script>
import { bus } from "@/router/bus";
import { filter_methods_including } from "@/utils/filter_methods_including";
import {getCookie} from "@/utils/cookie";

import AutoDeliveryCalculatePanel from "../common/AutoDeliveryCalculatePanel";
import RateRecommendTooltip from "../rate-recommends/RateRecommendTooltip.vue";
import RateCardNotFound from "./RateCardNotFound";
import TransporterCard from "@/components/rate-card/TransporterCard";
import RateRecommendList from "../rate-recommends/RateRecommendListNew.vue";
import RateCardNew from "../rate-card/RateCard.vue";
import RateListHeader from "./RateListHeader.vue";
import RateFilter from "@/components/rate-list/RateFilter.vue";
import AppLoader from "@/components/ui/AppLoader.vue";
import BannerList from "@/components/banner/BannerList.vue";
import BannerCard from "@/components/banner/BannerCard.vue";
import BannerListMobile from "@/components/banner/BannerListMobile.vue";
import NextPeriodRoute from '@/components/rate-list/NextPeriodRoute.vue';
import RequestForm from "@/components/request-form/RequestForm.vue";
import RateSortPanel from "./RateSortPanel.vue";
import AppSnackBar from "@/components/ui/AppSnackBar.vue";

export default {
  name: "RateList.vue",

  props: {
    searchParams: { type: Object }
  },

  components: {
    RequestForm,
    RateCardNotFound,
    TransporterCard,
    AutoDeliveryCalculatePanel,
    RateCardNew,
    RateRecommendList,
    RateRecommendTooltip,
    RateListHeader,
    RateFilter,
    BannerList,
    BannerCard,
    BannerListMobile,
    AppLoader,
    NextPeriodRoute,
    AppSnackBar,
    RateSortPanel
  },

  data: () => ({
    showTooltip: false,

    showRecommends: false,
    saveAttemptCount: 0,
    showModalNotify: false,
    showNextPeriodRoute: false,
    showAlternativeRoute: false,
    isDirectAlternative: false,
    next_period_route_data: [],

    ratelist: [],
    storeRateList: [],

    showFilter: false,

    transporterInfo: [],
    baseparams: {},
    showLoginForm: false,
    showBlockForm: false,
    showFilterForm: false,
    showSendLoading: false,
    current_rate: {},
    request_data: null,
    cid: null,
    ports: [],
    clients: [],
    stations: [],
    contractors: [],
    sea_start_weeks: [],

    chosenFilter: null,

    send_rate_request_data: {},
    showloader: 0,
    params: undefined,
    is_found: null,
    checkArrCount: 0,
    popupVisible: false,
    showtransporterinfo: false,

    autoCalculation: true,
    allowDeliveryCityIds: [72, 73, 74, 65],

    delivery_to_place: null,

    showMobileModal: false,
    activeMobileIndex: null,

    activeFilter: false,
    showFullBanner: false,
    banner: null,
    bannerImage: '',

    authStatus: !!localStorage.getItem('is_auth'),
    is_not_found: false,
    subscribeStatus: null,
    closeIcon: require('@/assets/img/icons/cancel.svg')
  }),

  inject: ['tripwireImages'],

  computed: {
    isAuth() {
      return this.authStatus;
    },

    windowSize() {
      return document.documentElement.clientWidth;
    },

    ratesLength() {
      return this.ratelist.filter(obj => obj.is_visible === 1).length;
    },

    isDeliveryCalculation() {
      return this.allowDeliveryCityIds.includes(Number(this.params.place_to));
    },

    clientId() {
      return Number(this.$_getsetting("client_id"))
    },

    subscribeMessage(){
      return this.subscribeStatus == 0 ? 'Подписка на направление оформлена' : 'Вы уже подписаны на это направление'
    }
  },

  watch: {
    ratelist: {
      handler(newValue, oldValue) {
        const isVisibleChanged = newValue.some((obj, index) => {
          return oldValue[index] && obj.is_visible !== oldValue[index].is_visible;
        });
        if (isVisibleChanged) {
          this.$nextTick(() => {
            this.ratesLength();
          });
        }
      },
      deep: true
    },
  },

  mounted() {
    this.getSearchParams()
    this.cid = this.$_getsetting("client_id");

    bus.$on("sortrates", (data) => {
      this.sortrates(data.type)
    });

    bus.$on("set_newsletter_rate", () => {
      if (this.isAuth) {
        this.setNewThreeBestRateParams()
      } else {
        this.showLoginForm = true
      }
    });

    bus.$on("getratelist", (data) => {
      this.showRecommends = false
      this.params = data;
      this.showloader = 0
      this.getRateList(data)
    });

    bus.$on("getratelistAlternative", (data) => {
      this.showRecommends = false
      this.params = data;
      this.showloader = 0
      this.getRateList(data)
    });

    bus.$on("getratelistAlternative", (data) => {
      this.showRecommends = false
      this.params = data;
      this.getRateList(data)
    });

    bus.$on("auth-update", () => {
      this.authStatus = !!this.$_getsetting('is_auth')
      this.getRateList(this.params)
      bus.$emit('changeSearchParams', this.params)
    });

    bus.$on("getratealarmlist", (data) => {
      this.getAlarmRateList(data)
    });

    bus.$on("showLineInfo", (args) => {
      this.getLineInfo(args)
    });

    bus.$on("filterratelist", (data) => {
      this.filterdata(data)
    });

    bus.$on("openFullBanner", (data) => {
      this.banner = data

      this.tripwireImages.tripwireBanners.forEach(img => { 
        if(data.id == img.id){
          this.bannerImage = img.img
        }
      })
    });

    bus.$on("show_send_request", (args) => {
      if (!this.isAuth) {
        this.showLoginForm = true
      } else {
        this.send_rate_request_data = args
        this.popupVisible = true
      }
    });

    if(!this.$_getsetting('BannerTimeShown')){
      this.checkBanner()
    }
  },

  methods: {
    getSearchParams() {
        if (this.searchParams && this.searchParams !== null) {
        this.params = this.searchParams
        this.getRateList(this.params)
      }
    },

    async getRateList(data) {
      this.timeBannerDiff();
      if (this.checkParameters(data)) {
        this.resetData();
        try {
          this.showloader = 1;

          const out_date = new Date(data.on_date);
          out_date.setDate(out_date.getDate());
          this.request_data = out_date;

          const token = this.$_getsetting('token');
          const uri = this.$api + (token ? this.$methods.getRateList.url : this.$methods.getRates.url);

          const headers = {
            headers: {
              "Accept": "application/json",
              "Content-Type": "application/json",
              ...(token && { "Authorization": `Bearer ${token}` })
            }
          };

          const params = {
            on_date: out_date,
            place_to: data.place_to,
            place_from: data.place_from,
            unit_code: data.unit_code,
            client_id: this.$_getsetting('client_id') || -99
          };

          const rate = await this.$postapi(uri, params, headers);

          if (rate.error === 0) {
            this.processRateList(rate, data);
          } else {
            this.showErrorNotification(rate.message);
            this.is_not_found = true;
          }
        } catch (error) {
          console.error(error);
        }

        this.getNexPeriodRate(data);
      } else {
        this.showErrorNotification("Указаны не все параметры для поиска!");
        this.is_not_found = true;
      }
    },

    resetData() {
      this.ratelist = []
      this.is_found = true
      this.showNextPeriodRoute = false
      this.isDirectAlternative = false
      this.showAlternativeRoute = false
      this.baseparams = {}
      this.ports = []
      this.clients = []
      this.stations = []
      this.contractors = []
      this.showFilter = false
      this.activeFilter = false
      this.lineMap = new Map();
    },

    processRateList(rate, data) {
      const parsedRate = JSON.parse(rate.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`));
      this.ratelist = parsedRate;
      this.storeRateList = parsedRate;
      this.sortDates(parsedRate);
      this.checkpage()
      this.sortrates(0)
      this.showloader = 0
      this.getAlternativeRoute(data, 'alarmratelist')
      this.fillFilterParam()
      this.getstartparams(true)

      this.getAlternativeRoute(data, 'ratelist');
    },

    showErrorNotification(message) {
      bus.$emit("show_notify", {
        color: "error",
        notifytext: message,
      });
    },

    getAlternativeRoute(data, type) {
      bus.$emit("getalternativeroute", {
        place_from: data.place_from,
        place_to: data.place_to,
        unit_code: data.unit_code,
        place_from_name: data.place_from_name,
        place_to_name: data.place_to_name,
        on_date: data.on_date,
        is_alt_to: this.getAlternativeRouteType(data.place_to),
        type: this.getAlternativeRouteType(data.place_to),
        alarm_class: data.alarm_class,
        client_id: this.$_getsetting('client_id'),
        method: type
      });

      bus.$on('currentaltroutes', (routes) => {
        routes.length > 0 ? this.showAlternativeRoute = true : this.showAlternativeRoute = false
      })
    },

    getAlternativeRouteType(place_to_id) {
      if ([72, 59, 65, 214, 73, 74].includes(Number(place_to_id))) {
        return 0;
      } else {
        return 1;
      }
    },

    getNexPeriodRate(data) {
      this.showNextPeriodRoute = false
      var D = new Date(data.on_date);
      if (Number(D.getDate()) > 18) {
        this.showNextPeriodRoute = true
        this.next_period_route_data = data
      }
    },

    getAlarmRateList(data) {
      this.resetData()
      this.isDirectAlternative = false
      this.ratelist = []
      if (this.checkParameters(data)) {
        this.params = data
        this.showloader = 1
        let out_date = new Date(data.on_date)
        out_date.setDate(out_date.getDate());
        this.request_data = out_date
        this.$postapi(this.$address + this.$getters.getRateListAlert.uri, {
          method: this.$getters.getRateListAlert.name,
          on_date: out_date,
          place_to: data.place_to,
          place_from: data.place_from,
          unit_code: data.unit_code,
          client_id: data.client_id,
          alarm_class: data.alarm_class,
          token: this.$_getsetting('token'),
        }).then(rate => {
          if (rate.error == 0) {
            if (rate.message != null) {
              this.ratelist = JSON.parse(rate.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`))
            }
            this.getAlternativeRoute(data, 'alarmratelist')
            this.fillFilterParam()
            this.checkpage()
            this.sortrates(0)
            this.showloader = 0;
            this.getstartparams(true)
          } else {
            bus.$emit("show_notify", {
              color: "error",
              notifytext: data.message
            });
          }
        });
        this.getNexPeriodRate(data) //for conflicts again
      } else {
        bus.$emit("show_notify", {
          color: "error",
          notifytext: "Указаны не все параметры для поиска!",
        });
      }
    },

    setNewThreeBestRateParams() {
      const uri = this.$api + this.$methods.setNewThreeBestRateParams.url
      const token = this.$_getsetting('token')
      const headers = {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      };

      const params = {
        client_id: this.$_getsetting('client_id'),
        description: '',
        place_from: this.params.place_from,
        place_to: this.params.place_to,
        unit_type:  this.params.unit_code
      }

      this.$postapi(uri, params, headers)
      .then((data) => {
        if (data.error == 0) {
          this.showModalNotify = true
          const res = JSON.parse(data.message)
          this.subscribeStatus = res.is_active
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message,
          });
        }
      });
    },

    sortDates(ratelist) {
      for (let i = 0; i < ratelist.length; i++) {
        if (ratelist[i].sea_start_dates) {
          let dates = ratelist[i].sea_start_dates.split(',')
          ratelist[i].sea_start_dates = dates.sort(function (a, b) {
            return new Date(a) - new Date(b);
          })
        }
      }
    },

    getLineInfo(line_id) {
      const uri = this.$api + this.$methods.getTransporterInfoById.url
      const headers = {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        }
      };

      const params = {
        line_id: line_id
      }
      this.$postapi(uri, params, headers)
      .then((data) => {
        if (data.error == 0) {
          this.transporterInfo = JSON.parse(data.message)[0]
          if (this.transporterInfo) {
            this.showtransporterinfo = !this.showtransporterinfo
          }
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message,
          });
        }
      });
    },

    checkParameters(data) {
      return (data.on_date != null && data.place_to > 0 && data.place_from > 0 && data.unit_code > 0)
    },

    sortrates(type) {
      switch (type) {
        case 0: {
          this.ratelist.sort(function (a, b) {
            return a.total - b.total;
          });
        }
          break
        case 1: {
          this.ratelist.sort(function (a, b) {
            return a.tranzit_time - b.tranzit_time;
          });
        }
          break
        case 2: {
          this.filterdata({ is_hot: [1] })
          this.ratelist.sort(function (a, b) {
            return a.total - b.total;
          });
        }
          break
        case 3: {
          this.ratelist.sort(function (a, b) {
            if (!a.sea_start_dates[0] && b.sea_start_dates[0]) return 1;
            if (!b.sea_start_dates[0] && a.sea_start_dates[0]) return -1;
            if (!b.sea_start_dates[0] && !a.sea_start_dates[0]) return 0;
            if (a.sea_start_dates[0] && b.sea_start_dates[0]) {
              return new Date(a.sea_start_dates[0]) - new Date(b.sea_start_dates[0]);
            }
          });
        }
          break
      }
    },

    getstartparams(reset) {
      this.baseparams = {
        minprice: this.getminprice(),
        maxprice: this.getmaxprice(),
        // mintztime: this.getmintztime(),
        // maxtztime: this.getmaxtztime(),
        ports: this.ports,
        clients: this.clients,
        stations: this.stations,
        contractors: this.contractors,
        sea_start_weeks: this.sea_start_weeks,

        reset: reset
      }
    },

    resetfilters() {
      if (this.chosenFilter !== 'ports') {
        this.ports = []
      }
      if (this.chosenFilter !== 'stations') {
        this.stations = []
      }
      if (this.chosenFilter !== 'contractors') {
        this.contractors = []
      }
      if (this.chosenFilter !== 'clients') {
        this.clients = []
      }
    },

    changeFilterName(value) {
      this.chosenFilter = value
    },

    fillFilterParam() {
      const terminalMap = new Map();
      const lineMap = new Map();
      const stationMap = new Map();

      this.ratelist.forEach(rate => {
        // Обработка терминалов
        if (!terminalMap.has(rate.terminal_id)) {
          terminalMap.set(rate.terminal_id, {
            name: rate.terminal_name,
            id: rate.terminal_id,
            line_ids: [],
            station_ids: [],
            is_fareast_region: rate.is_fareast_region,
            is_south_region: rate.is_south_region,
            is_northwest_region: rate.is_northwest_region,
          });
        }
        terminalMap.get(rate.terminal_id).line_ids.push(rate.line_id);
        terminalMap.get(rate.terminal_id).station_ids.push(rate.station_id);

        // Обработка линий
        if (!lineMap.has(rate.line_id)) {
          lineMap.set(rate.line_id, {
            name: rate.line_name,
            id: rate.line_id,
            terminal_ids: [],
            station_ids: [],
            is_fareast_region: rate.is_fareast_region,
            is_south_region: rate.is_south_region,
            is_northwest_region: rate.is_northwest_region,
          });
        }
        lineMap.get(rate.line_id).terminal_ids.push(rate.terminal_id);
        lineMap.get(rate.line_id).station_ids.push(rate.station_id);

        // Обработка станций
        if (!stationMap.has(rate.station_id)) {
          stationMap.set(rate.station_id, {
            name: rate.station_name,
            id: rate.station_id,
            terminal_ids: [],
            line_ids: [],
            is_fareast_region: rate.is_fareast_region,
            is_south_region: rate.is_south_region,
            is_northwest_region: rate.is_northwest_region,
          });
        }
        stationMap.get(rate.station_id).terminal_ids.push(rate.terminal_id);
        stationMap.get(rate.station_id).line_ids.push(rate.line_id);

        this.addClients(rate)
      });

      this.ports = Array.from(terminalMap.values());
      this.contractors = Array.from(lineMap.values());
      this.stations = Array.from(stationMap.values());
      this.add(this.clients, {
          id: 111111,
          name: "Без NAK"
        }
      )
    },

    addClients(rate) {
      for (let i = 0; i < rate.client_list.length; i++) {
        this.add(this.clients, {
          name: rate.client_list[i].client_name,
          id: rate.client_list[i].client_id,
        })
      }
    },

    sortFilterParams() {
      this.contractors.sort((a, b) => a.name.localeCompare(b.name))
      this.stations.sort((a, b) => a.name.localeCompare(b.name))
      this.clients.sort((a, b) => a.name.localeCompare(b.name))
      this.ports.sort((a, b) => a.name.localeCompare(b.name))
      this.getstartparams()
    },
    
    filterdata(data) {
      if (this.ratelist != null) {
        for (let i = 0; i < this.ratelist.length; i++) {
          this.ratelist[i].is_visible = 1
          if (this.checkvisible(data, this.ratelist[i])) {
            this.ratelist[i].is_visible = 1;
          } else {
            this.ratelist[i].is_visible = 0;
          }
        }
        this.checkFilterResult()
        this.changeIsNotSeaFilter(data.is_not_sea)
        this.getstartparams(false)
      }
    },

    changeIsNotSeaFilter(data) {
      if (data.includes(1,2)) {
        this.isDirectAlternative = false
      } else {
        this.isDirectAlternative = true
      }
    },

    checkvisible(data, item) {
      return filter_methods_including.methods.checkAll(item, data)
    },

    getminprice() {
      return this.ratelist.length ? this.ratelist.reduce((min, current) => Math.min(min, current.total), Infinity) : 0;
    },

    getmaxprice() {
      return this.ratelist.reduce((max, current) => Math.max(max, current.total), 0);
    },

    getmintztime() {
      let values = this.ratelist.map(function (v) {
        return v.tranzit_time;
      });
      return Math.min.apply(null, values);
    },

    getmaxtztime() {
      let values = this.ratelist.map(function (v) {
        return v.tranzit_time;
      });
      return Math.max.apply(null, values);
    },

    add(array, value) {
      if (value != null && value.name != null) {
        if (value.name.length > 0) {
          let inx = array.findIndex(function (item) {
            return item.id === value.id
          });
          if (inx < 0)
            array.push(value)
        }
      }
    },

    checkpage() {
      this.is_found = (this.ratelist.length > 0 && this.params != undefined) ? true : false;
      this.is_not_found = true
    },

    getListCount(){
      this.checkArrCount = 0
      for (let i = 0; i < this.ratelist.length; i++){
        if (this.ratelist[i].is_visible == 1){
          this.checkArrCount = 1
          break
        }
      }
    },

    resetRates() {
      this.delivery_to_place = null
      this.ratelist = JSON.parse(JSON.stringify(this.storeRateList))
      this.sortrates(0)
    },

    changeRatePrice(data) {
      this.delivery_to_place = data.delivery_to
      let chk = 0
      for (let rate of this.ratelist) {
        if (rate.across_place_name_id == data.route.point_from_id || rate.base_place_to == data.route.point_from_id) {
          for (let item of rate.detail) {
            if (item.type_id == 1222) {
              item.summa = data.route.price
              item.summa = data.route.price
              item.cost = data.route.price
              item.ext_place_to = ''
              item.place_to_id = ''
              item.place_to = data.delivery_to
              item.cbr_rate = 1
              item.cur_code = "RUB"
              chk = 1
              break
            }
          }
          if (chk == 0) {
            rate.detail.push({
              summa: data.route.price,
              cost: data.route.price,
              type_id: 1222,
              cbr_rate: 1,
              service: "Авто",
              ext_place_from: "",
              place_from: data.route.point_from,
              ext_place_to: "",
              place_to: data.delivery_to,
              icon: "4.png",
              place_to_id: '',
              transporter_name: '',
              cur_code:"RUB",
              service_id: 3
            })
          }

          if (rate.across_place_name_id == data.route.point_from_id) {
            rate.title = rate.base_place_from_name + ' - ' + rate.across_place_name + ' - ' + data.delivery_to
          } else {
            rate.title = rate.base_place_from_name + ' - ' + rate.across_place_name + ' - ' + rate.base_place_to_name + ' - ' + data.delivery_to
          }
        }
        this.getTotalOfRate(rate)
      }
      this.sortrates(0)
    },

    getTotalOfRate(rate) {
      let result = 0;
      for (let item of rate.detail) {
        if (isNaN(item.summa) === false) {
          result = result + item.summa * Math.round(item.cbr_rate)
        }
      }
      rate.total = Math.round(result)
      rate.all_time ++
    },

    checkFilterResult(){
      this.getListCount()
      this.is_found = !(this.checkArrCount == 0)
    },

    switchRecommends(data) {
      this.showRecommends = data
      this.showFilter = false
      if (data === false) {
        bus.$emit('resetFilter')
      }
    },

    checkBanner(){
      this.banner !== null && getCookie('bannerIndex') !== ''
      ? this.showFullBanner = true 
      : this.showFullBanner = false
    },

    timeBannerDiff(){
      let currentDate = Math.floor((new Date().getTime() / (1000 * 60)) % 60),
          savedBannerDate = this.$_getsetting('BannerTimeShown')
      if(Math.abs((currentDate - savedBannerDate)) >= 5){
        this.checkBanner()
      }
    },

    disableActiveFilter(){
      this.activeFilter = false
    },

  }
}
</script>

<template>
  <div>

    <RateRecommendTooltip class="recommend__header-title-tooltip" v-if="showTooltip">
      <div>
        Выбирает лучшее предложение по вашему направлению. В основе выбора лежит система индексов,
        включающая сроки, стоимость и другие.
        <br/>
        <a
            ref="tooltipContent"
            href="https://project.vedexx.ru/tpost/v1xmcglpt1-kak-vibrat-imenno-to-samoe-predlozhenie" target="_blank">
          Читать подробнее
        </a>
      </div>
    </RateRecommendTooltip>

    <div
      v-show="showRecommends === false"
      class="rate-list__auto g-container"
      v-if="cid == -1 && isDeliveryCalculation"
    >
      <div
        class="rate-list__open-calculation-btn"
        v-if="autoCalculation === false && cid == -1"
        @click="autoCalculation = true"
      >
        Рассчитать автовывоз
      </div>

      <AutoDeliveryCalculatePanel
        v-if="autoCalculation === true && cid == -1"
        class="rate-list__auto-calculate"
        :resetDelivery="showloader"
        :unit_code="Number(params.unit_code)"
        :city_from_id="Number(params.place_to)"
        @close="autoCalculation = false"
        @setTotalOfRate="[ $event.delivery_to != null ? changeRatePrice($event) : resetRates(), ]"
      />
    </div>
    <div class="rate-list g-col-lg-12">
      <div
        class="rate-list__loader-block"
         v-if="showloader == 1"
      >
        <AppLoader :size="80"/>
      </div>

      <div>
        <div class="rate-list__main-block">
          <div class="rate-list__main-block__card-container">
           

            <div class="recommend__header">
              <RateListHeader
                :showRecommends="showRecommends"
                :activeFilter="activeFilter"
                @openRecommends="switchRecommends($event)"
                @showTooltip="showTooltip = $event"
                @openFilter="showFilter = !showFilter"
                :showAlternativeRoute="showAlternativeRoute"
                @disableActiveFilter = disableActiveFilter
              />
            </div>
            <div>
              <RateFilter
                class="rate-list__filter"
                :baseparams="baseparams"
                :isAuth="isAuth"
                v-show="showFilter"
                @setFilter="activeFilter = $event"
                @selectFilter="changeFilterName($event)"
              />

              <RateSortPanel 
                v-if="!showRecommends"
                :rate_list_length="ratesLength"
              />
            </div>

            <NextPeriodRoute
              v-if="showNextPeriodRoute === true"
              :route="next_period_route_data"
              @nextRateList="[ratelist = $event, showNextPeriodRoute = false]"
              @close="showNextPeriodRoute = false"
            />

            <div class="rate-list__main-block__card-container__scroll-zone" v-if="is_found">
              <RateRecommendList
                class="rate-list__recommends"
                v-show="showRecommends === true"
                :ratelist="storeRateList"
                :is-auth="isAuth"
                @closeRecommend="showRecommends = false"
              />

              <div
                v-show="showRecommends === false"
                v-for="(item, index) in ratelist"
                :key="item.id"
              >
                <RateCardNew
                  v-if="((item.is_inside == 1 && (cid == -1 || cid == 0)) || item.is_inside == 0) && item.is_visible === 1"
                  class="rate-list__main-block__card-container-card"
                  :rate="item"
                  :index="index"
                  :delivery-place="delivery_to_place"
                  :is-auth="isAuth"
                />
              </div>
            </div>
            <RateCardNotFound v-else-if="showloader == 0 && is_not_found" :parameters="params"/>
          </div>

          <div class="rate-list__main-block__rate-parameters">
            <BannerList v-if="windowSize > 743" :load="showloader"/>
            <BannerListMobile v-else :load="showloader"/>
          </div>
        </div>
      </div>

      <v-dialog
        v-model="showFullBanner"
        class="banner-dialog"
        content-class="banner-dialog"
        transition="none"
        width="960"

        overlay-color="rgba(67, 79, 91, 0.30)"
        style="border-radius: 32px !important; box-shadow: none !important; z-index: 100"
      >
        <BannerCard
          v-if="showFullBanner"
          :banner="banner"
          :bannerImg="bannerImage"
          @closeBanner="showFullBanner = false"
        />
      </v-dialog>

      <v-dialog
        class="mt-editor-form"
        v-model="showtransporterinfo"
        persistent
        transition="dialog-bottom-transition"
        :width="windowSize > 1200 ? '50%' : '100%'"
        :height="windowSize > 1200 ? '60%' : '100%'"
        @click:outside="showtransporterinfo = false"
      >
        <TransporterCard
          :args="transporterInfo"
          @close="showtransporterinfo = false"
        />
      </v-dialog>

      <v-dialog
        v-model="popupVisible"
        :showModal="popupVisible"
        persistent
        transition="none"
        overlay-color="rgba(67, 79, 91, 0.80)"
        style="border-radius: 32px !important; box-shadow: none !important"
        max-width="1400px"
        :width="windowSize > 1200 ? '85%' : '100%'"
        :height="windowSize > 1200 ? '95%' : '100%'"
      >
          <RequestForm
            v-if="popupVisible"
            class="rate-list__main-block__request-form"
            :args="send_rate_request_data"
            :date="request_data"
            @close="popupVisible = false"
          />
      </v-dialog>

      <div class="snackbar g-container">
        <AppSnackBar
          :show="showModalNotify"
          :timeout="3000"
          background-color="var(--blue-100)"
          color="var(--blue-10)"
          @hide="showModalNotify = false"
        >
          {{ subscribeMessage }}
        </AppSnackBar>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  border-radius: 8px;
  background-color: rgba(0,0,0,.1);

  @media (max-width: $tablet-width) {
    width: 0;
  }

  @media (max-width: 600px) {
    width: 0;
  }
}
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  cursor: pointer;
  background-color: rgba(0,0,0,.4);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);

  @media (max-width: $tablet-width) {
    background: none;
  }
}

.rate-list {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  justify-content: center;
  margin: 24px auto 0;

  @media (max-width: $tablet-width) {
    display: block;
    padding: 0 32px;
    width: 100%;
    background: white;
    overflow: visible;
  }

  @media (max-width: $mobile-width) {
    width: 100%;
    overflow: visible;
    padding-left: unset;
    padding-right: unset;
  }

  @media (max-width: 400px) {
    padding: unset;
  }

  &__recommends {
    margin-top: $size-16;
    margin-bottom: 100px;

    @media (max-width: $mobile-width) {
      width: 100%;
      margin-top: 0;
      padding-top: $size-16;
      margin-bottom: 0;
      position: relative;
    }
  }

  &__open-calculation-btn {
    cursor: pointer;
    color: #FFFFFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    text-decoration: underline;
    margin: 0 auto 0;
    width: 100%;

    @media (max-width: $tablet-width) {
      color: var(--grey-100);
    }
  }

  &__auto {
    margin: 20px auto 0 auto;
    width: 100%;
    display: flex;
    height: 64px;

    @media (min-width: $desktop-width) {
      //width: calc(100% - 280px);
      max-width: 1408px;
    }

    @media (max-width: $mobile-width) {
      display: none;
    }

    &-calculate {
      width: 100%;
      display: flex;
    }
  }

  &__loader-block {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    background: none;
    z-index: 999;
  }

  &__filter {
    z-index: 0;
    top: 10px;
    position: sticky;
    width: 100%;
    margin-bottom: 24px;
  }

  &__main-block {
    width: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 24px;
    height: 100%;

    @media (min-width: $desktop-width) {
      width: 1200px;
    }

    @media (max-width: $tablet-width) {
      display: flex;
      width: 100%;
      flex-direction: column-reverse;
    }

    &__card-container {
      padding: 0 0 20px;
      border-radius: 10px;
      position: relative;
      width: 100%;
      background-color: #fff;
      min-height: 150px;
      height: auto;

      &.filter {
        max-height: 1600px;

        @media (max-width: $tablet-width) {
          max-height: unset;
        }
      }

      @media (max-width: $tablet-width) {
        width: 100%;
        position: relative;
        height: auto;
        max-height: unset;
      }

      @media (max-width: $mobile-width) {
        height: 100%;
        max-height: 100%;
        border-radius: 0;
        width: 100%;
        padding: 0 16px;
        overflow: visible;
      }

      &__scroll-zone {
        // overflow-y: auto;
        position: relative;
        height: calc(100% - 73px);
        background-color: #fff;

        &.filter {
          height: calc(100% - 550px);
        }
      }

      &-card {
        margin: 30px 0;
        width: 100%;
        max-width: 880px;

        @media (max-width: $tablet-width) {
          width: 100%;
          max-width: 100%;
          margin: $size-20 0;
        }
      }
    }

    &__request-form {
      width: 100%;
      height: 100%;
      overflow: auto;

      @media (max-width: $tablet-width) {
        height: 100%;
        width: 100%;
        max-height: 100%;
        margin: 0 auto;
      }
    }

    &__login-form {
      width: 60%;
    }

    &__rate-parameters {
      position: relative;
      height: auto;
      overflow: auto;
      top: 20px;
      align-self: start;
      border-radius: 10px;
      background: #FFFFFF;

      @media (max-width: $tablet-width) {
        width: 100%;
      }

      @media (max-width: $mobile-width) {
        max-width: 100%;
      }

      &-btn {
        color: #000;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-transform: none;
        letter-spacing: 0.22px;
        margin: 10px 0 10px 3px;

        @media (max-width: 1000px) {
          display: none;
        }
      }
    }
  }

  &__mobile-dialog {
    z-index: 3010;
  }

  &__notify {
    letter-spacing: 0.1px !important;

  }

  &__banner-dialog {
    position: fixed;
    display: flex;
    align-content: center;
    justify-content: center;
    width: 100%;
    background: rgba(67, 79, 91, 0.30);
  }
}

.banner-dialog {
  position: fixed;
  display: flex;
  border-radius: 32px !important;
  align-content: center;
  justify-content: center;
  width: 100% !important;
  box-shadow: none!important;
  background: rgba(67, 79, 91, 0.30);
}

.request-dialog {
  position: fixed;
  display: flex;
  border-radius: 32px !important;
  align-content: center;
  justify-content: center;
  width: 100%;
  box-shadow: none!important;
  background: rgba(67, 79, 91, 0.30);
}

.no-block-btn {
  text-transform: none;
  font-size: 22px;
  opacity: 1 !important;
  font-weight: 600;
  letter-spacing: 0.1px;
  padding: 0 !important;
  margin-bottom: 5px;
  text-decoration: underline;
}

.recommend__header {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  border-radius: 10px 10px 0 0;
  z-index: 1;
  background: #FFFFFF;
  margin-bottom: 24px;

  @media (max-width: $mobile-width) {
    z-index: 101;
    padding: 16px;
    width: 100%;
    margin-left: 0;
    position: sticky;
    top: 64px;
    border-radius: 0;
  }
}

.snackbar {
  width: 300px;

  @media (min-width: 2120px) {
    bottom: -700px;
    right: calc(20% - 40px);
  }
}

.recommend__header-title-tooltip {
  position: absolute;
  right: 0;
  font-size: 14px;
  top: 62px;
  z-index: 102 !important;

  @media (max-width: $tablet-width) {
    right: 36px;
    top: 280px;
  }

  @media (max-width: $mobile-width) {
    top: 350px;
  }
}

.card-main-text {
  font-size: 14px;
  color: black !important;

  &__list {
    list-style: disc !important;
    padding-left: 15px !important;
  }

  &__item-link {
    font-weight: 600;

    @include media-hover('&:active'){
      text-decoration: unset;
    }
  }
}

.notification__ttl {
  letter-spacing: 0.1px;
}
</style>
