<script>
import { bus } from "@/router/bus";

import PetersburgEmblem from "@/assets/img/icons/emblems/emblem_petersburg.webp"
import MoscowEmblem from "@/assets/img/icons/emblems/emblem_moscow.webp"
import EkbEmblem from "@/assets/img/icons/emblems/emblem_ekb.webp"

export default {
  name: "BestOfferCard",

  props: {
    rate: {
      type: [Array, Object],
    },

    port: {
      type: Object,
    },

    price: Object
  },

  data() {
    return {
      on_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    };
  },

  computed: {
    currentEmblem() {
      switch (this.rate.place_to_id) {
        case 65:
          return PetersburgEmblem;
        case 74:
          return EkbEmblem;
        default:
          return MoscowEmblem; 
      }
    },

    hasPrice(){
      return this.price.total && Object.keys(this.price).length > 0
    },
    
    isAuth(){
      return this.$_getsetting('is_auth')
    }
  },

  methods: {
    navigateToRateInfo(){
      if (!this.isAuth) {
        this.showLoginForm()
        return 
      }

      if (!this.hasPrice) {
        const rateListParams = {
          place_from: this.port.id,
          place_to: this.rate.place_to_id,
          unit_code: '8',
          on_date: this.on_date,
          place_from_name: this.port.name,
          place_to_name: this.rate.place,
          complex_rate: false,
        }

        this.openRateInfo('/RateListPage', rateListParams)
      }

      const rataPageParams = {
        key: this.price.key_orig, 
        on_date: this.on_date, 
        cid: this.$_getsetting("client_id")
      }

      this.openRateInfo('/RatePage', rataPageParams)
    },

    openRateInfo(path, params){
      let routePath = this.$router.resolve({
        path: path,
        query: params
      })
      
      window.open(routePath.href, '_blank');
    },

    showLoginForm() {
      bus.$emit("showloginform", "login");
    },
  },
};
</script>

<template>
  <div class="offer">
    <div class="offer-card" @click="navigateToRateInfo">
      <div class="offer-card__head">
        <img class="offer-card__logo" :src="currentEmblem" alt="logo" />
        <p class="offer-card__name">{{ rate.place }}</p>
      </div>
      <div class="offer-card__body">
        <p v-if="price.total" class="offer-card__price">{{parseFloat(price.total).toLocaleString('ru-RU')}} р</p>
        <a class="offer-card__more-btn" href="#!">Подробнее</a>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.offer{
  display: flex;
  flex-direction: column;
  gap: 24px 0;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: unset;
  }
}

.offer-card {
  border-radius: 12px;
  background-color: var(--blue-10);
  padding: 16px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px 0;

  max-height: 136px;
  cursor: pointer;

  &__head {
    display: flex;
    gap: 0 12px;
    align-items: center;
    position: relative;

    &:before {
      position: absolute;
      content: "";
      border: 0.5px solid var(--grey-30);
      width: 100%;
      bottom: -12px;
    }
  }

  &__logo {
    height: 32px;
    width: 41px;
  }

  &__body {
    display: flex;
    justify-content: space-between;
  }

  &__name {
    @include font-headline-2;
    color: var(--grey-70);
  }

  &__price {
    @include font-headline-2;
    color: var(--grey-70);
  }

  &__more-btn {
    @include font-body-2;
    font-weight: 600;
    text-decoration: none;
    color: var(--grey-70);
  }
}
</style>