<script>
import {bus} from "../../router/bus";
import { reachYMGoal } from "@/utils/metrika";

export default {
  name: 'FaqBlock',
  components: {
  },
  
  data() {
    return {
      questions: [],
      currentPage: 1,
      totalPages: 1,
    }
  },
  
  mounted() {
    this.getFaqs()
  },
  
  methods: {
    getFaqs(page = 1) {
      const params = {
        'page': page
      }

      this.$getapi(this.$api + this.$methods.faq.url, { params })
        .then(data => {
          if (page === 1) {
            this.questions = data.data;
          } else {
            this.questions = [...this.questions, ...data.data];
          }
          this.currentPage = page;
          this.totalPages = data.meta.last_page;
        }).catch(err => {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: err.message
          });
        });
    },

    
    showMore() {
      if (this.currentPage < this.totalPages) {
        this.getFaqs(this.currentPage + 1);
      }
    },

    addMetrics(name) {
      reachYMGoal(this, name)
    },
  }
}
</script>

<template>
  <div class="faq-block">
    <v-expansion-panels v-if="questions.length > 0" accordion>
      <transition-group name="list" tag="div" class="faq-block__item" style="width: 110%">
      <v-expansion-panel
        v-for="(question, index) in questions"
        :key="question.id"
      >
        <v-expansion-panel-header color="faq-block__panel" @click="addMetrics((index+1) + 'Question')" hide-actions>
          <template v-slot:default="{ open }">
            <div class="faq-block__panel-question">{{question.question}}</div>
              <img class="faq-block__panel-question-image" v-if="open" src="../../assets/img/icons/mdi/mdi-minus-circle.svg" alt="mdi-minus-circle">
              <img class="faq-block__panel-question-image" v-else src="../../assets/img/icons/mdi/mdi-plus-circle.svg" alt="mdi-minus-circle">
          </template>
        </v-expansion-panel-header>
        
        <v-expansion-panel-content>
          <div class="faq-block__panel-answer" v-html="question.answer"></div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      </transition-group>
    </v-expansion-panels>
    
    <div
      v-if="currentPage < totalPages"
      @click="showMore"
      class="faq-block__show-more"
    >
      Показать больше
    </div>
  </div>
</template>

<style scoped lang="scss">

.list-enter-active, .list-leave-active {
  transition: all 0.7s;
}

.list-enter, .list-leave-to /* .list-leave-active до версии 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}

.faq-block {
  margin: $size-20 0;
  display: flex;
  flex-direction: column;
  
  &__item {
    width: 100% !important;
  }
  
  &__panel {
    min-height: 90px;

    
    &-question {
      @include font-headline-2;

      width: 90%;
      padding-left: 10px;

      &-image{
        width: 30px;
        height: 30px;
      }
      
      @media (max-width: $tablet-width) {
        font-size: 20px;
      }
    }
    
    &-answer {
      @include font-body-2;

      width: 80%;
      padding-left: 10px;
      padding-bottom: 20px;
      overflow-wrap: break-word; 
    }
  }
  
  &__show-more {
    @include font-description-semibold-0;
    
    margin-top: $size-24;
    display: flex;
    width: 100%;
    color: black;
    align-items: flex-end;
    justify-content: flex-end;
    text-align: center;
    cursor: pointer;
    z-index: 2;
    @include right-gap;
    
    &:hover {
      text-decoration: underline;
    }
    
    &:after {
      content: '';
      text-align: center;
      line-height: 10px;
      background: url('../../assets/img/icons/mdi/arrow-down-2-duotone.svg') no-repeat;
      background-size: 100%;
      width: 20px;
      height: 19px;
    }
  }
}
</style>
