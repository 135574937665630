<script>
import ReviewCard from "./ReviewCard.vue";

export default {
  name: 'ReviewList',
  components: {
    ReviewCard
  },

  props: {
    categoryReview: {type: String, default: 'default'}
  },
  
  data() {
    return {
      showFullReview: false,
      clients_reviews: [],
      windowWidth: window.innerWidth,
    }
  },
  
  computed: {
    columns() {
      if (this.$vuetify.breakpoint.xl) {
        return 4;
      }
      
      if (this.$vuetify.breakpoint.lg) {
        return 3;
      }
      
      if (this.$vuetify.breakpoint.md) {
        return 2;
      }
      
      return 1;
    }
  },
  
  mounted() {
    this.getClientReviewsList()
    window.addEventListener('resize', this.handleResize);
    this.handleResize()
  },
  
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth
    },
    
    getClientReviewsList() {
      const params = {
        'testimonial_categories_filter': [ this.categoryReview ]
      }

      const url = `${this.$api + this.$methods.getReviewsList.url}`

      this.$getapi(url, { params })
      .then(res => {
        this.clients_reviews = [...res.data]
      })
      .catch(err => {
        console.log('Ошибка получения отзывов:', err)
      });
    },
  }
}
</script>

<template>
  <div v-if="clients_reviews.length > 0" class="reviews">
    <h2 class="reviews__title">Отзывы клиентов</h2>
    
    <v-carousel
      v-show="windowWidth > 743"
      hide-delimiters
      hide-delimiter-background
    >
      <template v-slot:prev="{ on, attrs }" class="slider__prev">
        <v-btn
          v-bind="attrs"
          class="slider__prev"
          v-on="on"
          color="white"
          rounded
          height="50px"
          small
        >
          <img class="slider__prev-arrow" src="../../../assets/img/icons/mdi/mdi-arrow-left-thin.svg" alt="arrow-left-thin">
        </v-btn>
      </template>
      
      <template v-slot:next="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          class="slider__next"
          v-on="on"
          color="white"
          rounded
          height="50px"
          small
        >
          <img class="slider__prev-arrow" src="../../../assets/img/icons/mdi/mdi-arrow-right-thin.svg" alt="arrow-right-thin">
        </v-btn>
      </template>
      
      <template
        v-for="(review, index) in clients_reviews"
      >
        <v-carousel-item
          class="reviews__item"
          v-if="(index + 1) % columns === 1 || columns === 1"
          :key="index"
        >
          <v-row class="flex-nowrap" style="height:100%">
            
            <template v-for="(n,i) in columns">
              <template v-if="(+ index + i) < clients_reviews.length">
                <v-col :key="i">
                  <v-sheet
                    v-if="(+ index + i) < clients_reviews.length"
                    height="90%"
                  >
                    <v-row
                      class="fill-height reviews__list g-row"
                      align="center"
                      justify="start"
                    >
                      <ReviewCard
                        class="reviews__list__card g-col-lg-3 g-col-md-4 g-col-sm-3 g-col-xs-2"
                        @openReview="showFullReview = true"
                        :review="clients_reviews[+index + i]"
                      />
                    </v-row>
                  </v-sheet>
                </v-col>
              </template>
            </template>
          </v-row>
        </v-carousel-item>
      </template>
    </v-carousel>
    
    <div class="mobile-list" v-show="windowWidth <= 743">
      <ReviewCard
        v-for="review in clients_reviews"
        :key="review.id"
        :review="review"
        class="mobile-list__card"
        @openReview="showFullReview = true"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">

.reviews {
  //margin-top: -35px;
  &__list {
    margin-right: 1%;
    padding-top: 100px;
    @include left-gap;
    
    @media (max-width: 743px) {
      overflow-x: scroll;
      display: flex;
    }
    
    &__card {
      position: relative;
      width: auto;
      @include right-gap;
      
      @media (max-width: 743px) {
        width: 100%;
        display: flex;
        //width: calc(100% - 64px);
      }
    }
  }

  &__title{
    @include font-headline-1;

    margin: 40px 0 30px;
    letter-spacing: 0.01em;
    color: #000000;
  }
}

.slider__prev {
  margin-left: -12px !important;
  margin-right: -12px !important;
  
  @media (max-width: 743px) {
    display: none;
  }

  &-arrow{
    width: 24px;
    height: 24px;
  }
}

.mobile-list {
  display: flex;
  overflow-x: scroll;
  padding: 60px 5px 10px;
  
  &__card {
    //position: relative;
    //width: 320px;
    @include right-gap;
  }
}

.slider__next {
  
  @media (max-width: 743px) {
    display: none;
  }
}

</style>
