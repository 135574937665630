export const getMergedPortsAndRates = (ports, rates, on_date) => {
  const result = [];
  ports.forEach(port => {
    rates.forEach(rate => {
      result.push({
        on_date,
        place_to: rate.place_to_id,
        place_from: port.id,
        unit_code: 6,
      });
    });
  });
  return result;
};
