<script>
export default {
  name: "ArticlesSlide",
};
</script>

<template>
  <div class="articles-slide">
    <div class="container">
      <div class="articles-slide__items">
        <div class="articles-slide__item">
          <img
            class="articles-slide__logo"
            src="./img/vedexx-img.webp"
            alt="logo"
          />

          <h3 class="articles-slide__title">
            Новый онлайн-сервис: <br />
            <span class="articles-slide__title_bold"
              >Мгновенный поиск всех <br />
              артикулов в декларации</span
            >
          </h3>
          <p class="articles-slide__subtitle">
            Vedexx найдёт их быстрее, <br />
            чем вы дочитаете эту рекламу
          </p>

          <router-link class="articles-slide__btn" to="/services/articles?utm_source=main-page&utm_medium=banner">Попробовать бесплатно</router-link>
        </div>

        <picture class="articles-slide__img">
          <source
            media="(max-width: 767px)"
            srcset="./img/akukotik-mobile.webp"
          />

          <source
            media="(max-width: 1440px)"
            srcset="./img/akukotik-tabletop.webp"
          />

          <source srcset="./img/akukotik-desktop.webp" />

          <img
            class="articles-slide__akulenok"
            src="./img/akukotik-desktop.webp"
            srcset="./img/akukotik-desktop.webp"
            alt="Акуленок"
          />
        </picture>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container {
  max-width: 1630px;
  margin: 0 auto;
  padding: 0 16px;
  height: 100%;

  @media (min-width: 1900px) {
    position: relative;
  }
}

.articles-slide {
  position: relative;
  height: 100%;
  background: linear-gradient(283deg, #ccd5e2 3.81%, #fff 88.03%);

  &__items {
    @media (max-width: 960px) {
      display: flex;
      height: 100%;
      align-items: center;
    }

    @media (max-width: 770px) {
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
    }
  }

  &__item {
    @media (max-width: 770px) {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }

  &__logo {
    padding-top: 130px;
    margin-bottom: 50px;
    max-width: 200px;

    @media (max-width: 770px) {
      padding-top: 50px;
    }
  }

  &__title {
    color: var(--blue-70);
    margin-bottom: 32px;

    @include font-headline-2;

    &_bold {
      @include font-title-2;

      @media (max-width: 960px) {
        @include font-headline-1;
      }

      @media (max-width: 500px) {
        @include font-headline-2;
      }
    }

    @media (max-width: 960px) {
      margin-bottom: 15px;

      @include font-body-1;
    }

    @media (max-width: 770px) {
      text-align: center;
    }
  }

  &__subtitle {
    color: var(--grey-60);
    margin-bottom: 74px;

    @include font-headline-2;

    @media (max-width: 960px) {
      margin-bottom: 40px;

      @include font-body-3;
    }
  }

  &__btn {
    max-width: fit-content;
    height: 60px;
    background-color: var(--orange-70);
    padding: 15px 50px;
    border-radius: 16px;
    text-decoration: unset;
    color: #FFF;
    transition: opacity .2s ease-in-out;

    display: flex;
    align-items: center;

    @include media-hover('&:active') {
        opacity: .8;
    }

    @include font-headline-2;

    @media (max-width: 960px) {
      height: 50px;

      @include font-headline-3;
    }

    @media (max-width: $mobile-width) {
        padding: 5px 20px;
        height: 40px;
     

        @include font-description-semibold-0;
    }
  }

  &__img {
    position: absolute;
    bottom: 0;
    right: 0;
    object-fit: cover;

    @media (max-width: 1440px) {
      right: -120px;
      bottom: 0;
      top: unset;
    }

    @media (max-width: 960px) {
      bottom: 0;
      right: -50px;
    }

    @media (max-width: 770px) {
      position: relative;

      inset: unset;
    }
  }

  &__akulenok {
    max-width: 1040px;
    object-fit: cover;

    @media (max-width: 1440px) {
      max-width: 960px;
      height: 100%;
    }

    @media (max-width: 960px) {
      max-width: 700px;
      object-fit: contain;
    }

    @media (max-width: 770px) {
      max-width: 400px;
      width: 100%;
    }
  }
}
</style>
