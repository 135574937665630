<script>
import AppButton from "@/components/ui/AppButton.vue";

import { getCurrentDate } from "@/utils/getCurrentDate.js";

export default {
  name: "NewRouteSlide.vue",

  components: {
    AppButton,
  },

  data() {
    return {
      date: getCurrentDate(),
    };
  },

  methods: {
    createRatesPath(country) {
      this.dateCheck();

      let pathParams = {};

      if (country === "Mundra") {
        pathParams = {
          place_from: "39",
          place_to: "59",
          unit_code: "8",
          on_date: this.date,
          place_from_name: "Mundra",
          place_to_name: "Novorossiysk",
          complex_rate: false,
        };
      }

      if (country === "Istanbul") {
        pathParams = {
          place_from: "53",
          place_to: "59",
          unit_code: "8",
          on_date: this.date,
          place_from_name: "Istanbul",
          place_to_name: "Novorossiysk",
          complex_rate: false,
        };
      }

      const utmParams = {
        utm_source: "main-page",
        utm_medium: "banner",
      };

      const finalParams = {
        ...pathParams,
        ...utmParams,
      };

      this.openRateInfo("/RateListPage", finalParams);
    },

    openRateInfo(path, params) {
      let routePath = this.$router.resolve({
        path: path,
        query: params,
      });

      window.open(routePath.href, "_blank");
    },

    dateCheck() {
      // Временный метод с проверкой  до ожидания бека,
      //после того как бек сделают, нужно убрать

      const today = new Date(this.date);

      if (today.getDate() === 31) {
        today.setDate(today.getDate() + 1);
        this.date = today.toISOString().split("T")[0];
      }
    },
  },
};
</script>

<template>
  <div class="new-routes">
    <div class="container">
      <div class="new-routes__items">
        <div class="new-routes__item">
          <h3 class="new-routes__title">
            Новые маршруты: <br />
            <span class="new-routes__title_dark-blue"
              >Индия и Турция → <br />Новороссийск</span
            >
          </h3>

          <p class="new-routes__subtitle">
            Vedexx расширяет географию<br />и варианты доставки
          </p>

          <div class="new-routes__btns">
            <p class="new-routes__btn-text">Найти выгодную ставку:</p>

            <AppButton
              class="new-routes__btn"
              label="Индия"
              size="large"
              @click="createRatesPath('Mundra')"
            />
            <AppButton
              class="new-routes__btn"
              label="Турция"
              size="large"
              @click="createRatesPath('Istanbul')"
            />
          </div>
        </div>

        <div class="new-routes__item new-routes__item_img">
          <picture>
            <source
              media="(max-width: 767px)"
              srcset="./img/globus_mobile.webp"
            />

            <source
              media="(max-width: 1440px)"
              srcset="./img/globus_tabletop.webp"
            />

            <source srcset="./img/globus.webp" />

            <img
              class="new-routes__img"
              src="./img/globus.webp"
              srcset="./img/globus.webp"
              alt="Глобус"
            />
          </picture>

          <ul class="new-routes__list">
            <li class="new-routes__list-item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
              >
                <rect
                  x="7.43164"
                  y="8.625"
                  width="26"
                  height="26"
                  rx="3.25"
                  stroke="#6791CF"
                  stroke-width="3.25"
                />
                <rect
                  x="13.1191"
                  y="4.5625"
                  width="1.625"
                  height="4.875"
                  rx="0.8125"
                  stroke="#6791CF"
                  stroke-width="1.625"
                />
                <rect
                  x="26.1191"
                  y="4.5625"
                  width="1.625"
                  height="4.875"
                  rx="0.8125"
                  stroke="#6791CF"
                  stroke-width="1.625"
                />
                <rect
                  x="12.3066"
                  y="20"
                  width="3.25"
                  height="3.25"
                  rx="1.625"
                  fill="#6791CF"
                />
                <rect
                  x="12.3066"
                  y="26.5"
                  width="3.25"
                  height="3.25"
                  rx="1.625"
                  fill="#6791CF"
                />
                <rect
                  x="18.8066"
                  y="20"
                  width="3.25"
                  height="3.25"
                  rx="1.625"
                  fill="#6791CF"
                />
                <rect
                  x="18.8066"
                  y="26.5"
                  width="3.25"
                  height="3.25"
                  rx="1.625"
                  fill="#6791CF"
                />
                <rect
                  x="25.3066"
                  y="20"
                  width="3.25"
                  height="3.25"
                  rx="1.625"
                  fill="#6791CF"
                />
                <rect
                  x="25.3066"
                  y="26.5"
                  width="3.25"
                  height="3.25"
                  rx="1.625"
                  fill="#6791CF"
                />
                <path
                  d="M5.80664 15.125H35.0566"
                  stroke="#6791CF"
                  stroke-width="3.25"
                />
              </svg>
              Точный прогноз сроков доставки
            </li>

            <li class="new-routes__list-item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="40"
                viewBox="0 0 36 40"
                fill="none"
              >
                <path
                  d="M29.097 34.9997H6.76367C6.02729 34.9997 5.43034 34.4027 5.43034 33.6663V10.7404V6.33301C5.43034 5.59663 6.02729 4.99967 6.76367 4.99967H14.0667H21.7107C22.0977 4.99967 22.4656 5.16783 22.7189 5.46046L30.1052 13.995C30.3149 14.2374 30.4303 14.5471 30.4303 14.8676V16.8747V19.9997V33.6663C30.4303 34.4027 29.8334 34.9997 29.097 34.9997Z"
                  stroke="#6791CF"
                  stroke-width="3.33333"
                />
                <path
                  d="M20.4297 5V13C20.4297 14.1046 21.3251 15 22.4297 15H28.763"
                  stroke="#6791CF"
                  stroke-width="3.33333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.7637 24.9997L17.097 28.333L22.9303 20.833"
                  stroke="#6791CF"
                  stroke-width="3.33333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Один договор на всю перевозку
            </li>

            <li class="new-routes__list-item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="41"
                height="40"
                viewBox="0 0 41 40"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.2728 8.28422C14.0954 7.06637 16.2383 6.41634 18.4303 6.41634C19.2127 6.41634 19.847 5.78208 19.847 4.99967C19.847 4.21727 19.2127 3.58301 18.4303 3.58301C15.6779 3.58301 12.9872 4.39921 10.6987 5.92839C8.41007 7.45757 6.62634 9.63106 5.57302 12.174C4.5197 14.7169 4.24411 17.5151 4.78108 20.2147C5.31806 22.9142 6.64349 25.394 8.58978 27.3402C10.5361 29.2865 13.0158 30.612 15.7153 31.1489C18.4149 31.6859 21.2131 31.4103 23.756 30.357C24.8292 29.9125 25.8366 29.3378 26.7575 28.6501L34.9826 36.0527C35.5642 36.5761 36.4599 36.5289 36.9833 35.9474C37.5067 35.3658 37.4596 34.4701 36.878 33.9467L28.8517 26.723C29.2667 26.2541 29.6511 25.756 30.0016 25.2314C31.5308 22.9428 32.347 20.2521 32.347 17.4997C32.347 16.7173 31.7127 16.083 30.9303 16.083C30.1479 16.083 29.5137 16.7173 29.5137 17.4997C29.5137 19.6917 28.8637 21.8346 27.6458 23.6572C26.4279 25.4799 24.697 26.9005 22.6718 27.7393C20.6465 28.5782 18.418 28.7977 16.2681 28.37C14.1181 27.9424 12.1433 26.8868 10.5932 25.3368C9.04321 23.7867 7.98763 21.8119 7.55997 19.6619C7.13232 17.512 7.35181 15.2835 8.19068 13.2583C9.02955 11.2331 10.4501 9.50207 12.2728 8.28422Z"
                  fill="#6791CF"
                />
                <rect
                  x="16.0957"
                  y="9.99805"
                  width="13"
                  height="3"
                  rx="1.5"
                  fill="#6791CF"
                />
                <rect
                  x="16.0957"
                  y="14.998"
                  width="8"
                  height="3"
                  rx="1.5"
                  fill="#6791CF"
                />
                <rect
                  x="16.0957"
                  y="20"
                  width="5"
                  height="3"
                  rx="1.5"
                  fill="#6791CF"
                />
              </svg>
              Все предложения в одном окне
            </li>
          </ul>
        </div>

        <div class="new-routes__btns new-routes__btns_mobile">
          <p class="new-routes__btn-text">Найти выгодную ставку:</p>

          <AppButton
            class="new-routes__btn"
            label="Индия"
            size="large"
            @click="createRatesPath('Mundra')"
          />
          <AppButton
            class="new-routes__btn"
            label="Турция"
            size="large"
            @click="createRatesPath('Istanbul')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container {
  max-width: 1630px;
  margin: 0 auto;
  padding: 0 16px;
  height: 100%;
}

.new-routes {
  background: linear-gradient(283deg, #ccd5e2 3.81%, #fff 88.03%);
  height: 100%;

  @media (max-width: 600px) {
    padding-top: 30px;
  }

  &__title {
    color: var(--blue-50);
    margin-bottom: 48px;

    @include font-headline-1;

    &_dark-blue {
      color: var(--blue-70);
      @include font-title-1;

      @media (max-width: 1440px) {
        @include font-title-2;
      }

      @media (max-width: 810px) {
        @include font-headline-1;
      }
    }

    @media (max-width: 1440px) {
      @include font-title-2;

      margin-bottom: 31px;
    }

    @media (max-width: 810px) {
      @include font-headline-2;

      text-align: center;
      margin-bottom: 8px;
    }
  }

  &__subtitle {
    color: var(--grey-60);
    margin-bottom: 56px;

    @include font-headline-1;

    @media (max-width: 1440px) {
      margin-bottom: 33px;
      @include font-headline-2;
    }

    @media (max-width: 810px) {
      margin-bottom: unset;
      text-align: center;

      @include font-headline-3;
    }
  }

  &__btn-text {
    text-align: center;
    color: var(--orange-70);

    @include font-headline-2;

    @media (max-width: 1440px) {
      @include font-headline-3;
    }

    @media (max-width: 810px) {
      @include font-body-bold-3;
    }
  }

  &__btns {
    max-width: 519px;

    display: flex;
    flex-direction: column;
    gap: 23px;

    @media (max-width: 1440px) {
      max-width: 330px;
    }

    @media (max-width: 810px) {
      display: none;
      gap: 20px;
    }

    &_mobile {
      display: none;

      @media (max-width: 810px) {
        display: flex;
        margin-top: -40px;
        z-index: 1;

        max-width: 100%;
        width: 50%;
      }

      @media (max-width: 500px) {
        width: 70%;
        margin-top: 0;
      }
    }
  }

  &__btn {
    height: 80px;

    @media (max-width: 1440px) {
      height: 64px;
    }

    @media (max-width: 810px) {
      height: 36px;
    }
  }

  &__items {
    display: flex;
    align-items: center;
    height: 100%;

    @media (max-width: 810px) {
      flex-direction: column;
    }
  }

  &__item {
    position: relative;
    flex-basis: 45%;

    &_img {
      flex-basis: 55%;
    }

    @media (max-width: $tablet-width) {
      flex-basis: 50%;
    }

    @media (max-width: 810px) {
      flex-basis: unset;
    }
  }

  &__img {
    max-width: 892px;
    width: 100%;

    object-fit: cover;

    @media (max-width: 810px) {
      max-width: 500px;
    }
  }

  &__list {
    position: absolute;
    bottom: 130px;
    left: -30px;

    display: flex;
    flex-direction: column;
    gap: 44px;

    counter-reset: number 0;

    @media (max-width: 1440px) {
      gap: 28px;
    }

    @media (max-width: $tablet-width) {
      bottom: 0;
      left: 0;
    }

    @media (max-width: 810px) {
      left: 50%;
      bottom: 60px;
      transform: translateX(-50%);

      gap: 18px;
      width: 60%;
    }

    @media (max-width: 500px) {
      bottom: 20px;
      width: 70%;
    }

    &-item {
      display: flex;
      align-items: center;
      gap: 10px;

      position: relative;
      height: 80px;
      border-radius: 23px;

      padding: 20px;
      width: 460px;

      backdrop-filter: blur(15px);
      background: rgba(255, 255, 255, 0.5);
      color: var(--grey-70);

      counter-increment: number 1;

      @include font-headline-2;

      @media (max-width: 1440px) {
        width: fit-content;
        height: 50px;
        padding: 10px 20px;
        border-radius: 15px;

        @include font-description-semibold-1;
      }

      @media (max-width: 810px) {
        width: 100%;
        height: 40px;
        border-radius: 10px;

        @include font-description-1;
      }

      &:after {
        content: counter(number) ".";
        position: absolute;

        top: -30px;
        left: -40px;
        padding-left: 5px;

        width: 64px;
        height: 64px;

        background-color: var(--orange-70);
        color: #fff;
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;

        @include font-headline-1;

        @media (max-width: 1440px) {
          width: 40px;
          height: 40px;

          top: -20px;
          left: -20px;

          @include font-body-bold-1;
        }

        @media (max-width: 810px) {
          width: 23px;
          height: 23px;

          top: -10px;
          left: -10px;
          padding-left: 2px;

          @include font-description-semibold-1;
        }
      }

      &:before {
        content: "";
        position: absolute;
        inset: 0;
        padding: 2px;
        background-image: linear-gradient(to bottom, #d2e3ff99, #2f5da8cc);
        border-radius: inherit;

        mask: conic-gradient(#000 0 0) content-box exclude,
          conic-gradient(#000 0 0);
      }

      &:nth-child(2) {
        margin-left: 90px;

        @media (max-width: $tablet-width) {
          margin-left: 50px;
        }
      }

      &:nth-child(3) {
        margin-left: 230px;

        @media (max-width: $tablet-width) {
          margin-left: 110px;
        }
      }

      @media (max-width: 810px) {
        &:nth-child(2),
        &:nth-child(3) {
          margin-left: unset;
        }
      }

      svg {
        @media (max-width: 1440px) {
          height: 25px;
          width: 25px;
        }
      }
    }
  }
}
</style>
